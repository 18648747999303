import React, { useState, useEffect } from 'react';
import { Link } from "react-scroll";
import './App.css';
import linkedIn from './images/LinkedIn.png';
import gitHub from './images/github.png';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBackgroundActive, setIsBackgroundActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleSetActive = (section) => {
    if (section === "projectsContainer" || section === "contact-content") {
      setIsBackgroundActive(true);
    } else {
      setIsBackgroundActive(false);
    }
  };

  useEffect(() => {
    if (isBackgroundActive) {
      document.body.classList.add("body-with-background");
    } else {
      document.body.classList.remove("body-with-background");
    }
  }, [isBackgroundActive]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`App ${isBackgroundActive ? 'body-with-background' : ''}`}>
      <nav className={`navbar ${isDropdownOpen ? 'active' : ''} ${scrolled ? 'scrolled' : ''}`}>
        <div className="myName">Rose W.</div>
        <a
          href="#"
          className={`toggleButton ${isDropdownOpen ? 'active' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            setIsDropdownOpen(!isDropdownOpen);
          }}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </a>
        <div className={`navbarLinks ${isDropdownOpen ? 'active' : ''}`}>
          <ul>
            <li>
              <Link
                to="welcomeContainer"
                smooth={true}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="projectsContainer"
                smooth={true}
                duration={500}
                onSetActive={() => handleSetActive("projectsContainer")}
              >
                Portfolio
              </Link>
            </li>
            <li>
            <Link
  to="contact-content"
  smooth={true}
  duration={500}
  onSetActive={() => handleSetActive("contact-content")}
>
  Contact
</Link>
            </li>
          </ul>
        </div>
      </nav>


      <div className="container">
      <div className="welcomeContainer" id="welcomeContainer">
          <div className="welcomeText">
            <h1>Rose W.</h1>
            <p>A Front-End Developer who loves Technology & Art!</p>
            <div className="bouncingText">Explore My Universe!</div>
          </div>
          <div className="fullWidthImage"></div>
        </div>
      </div>

      
        <div className="codingContainer">
        <div className="leftContent" >
        <div className='sectionHeading'>About Me!</div>
            <div className="aboutText projectItem">
              <h4>How did I get into coding?</h4>
              <p>After my employment at Cognizant, I discovered a deep-seated passion for coding. Working primarily with CSS, React, and AWS, I became engrossed in the world of web development and cloud computing, igniting my enthusiasm for these technologies and their limitless possibilities. As I look ahead, my goal is to transition into full-stack development, fueled by my unwavering commitment to continuous learning and progress</p>
            </div>

            <section className="contact-form projectItem" id="contact-content">
              <div className="contact-content">
                <div className="contact-email">
                  
                  <h4>Contact Me</h4><p>roselynwillp@gmail.com</p>
                </div>
                <div className="contact-social">
                  <ul>
                    <li>
                      <a className="codePill" href="https://www.linkedin.com/in/rose-will-33923b198/" target="_blank" rel="noopener noreferrer">
                        <img src={linkedIn} alt="LinkedIn" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a className="codePill" href="https://github.com/RoselynW" target="_blank" rel="noopener noreferrer">
                        <img src={gitHub} alt="GitHub" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                        GitHub
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            
          </div>
          <div className="rightContent">
            
          <div className="projectsContainer" id="projectsContainer">
          <div className='sectionHeading'>Portfolio</div>
              <div className="projectItem">
                <h3 className="projectItemTitle">
                  <a href="#" target="_blank" rel="noopener noreferrer">This Website</a>
                </h3>
                <div className="projectItemDescription">
                  This website was built using React and styled with CSS. It includes a responsive layout and various interactive features.
                </div>
                <div className="codePill">React</div>
                <div className="codePill">CSS</div>
                <div className="codePill">AWS-CDK</div>
                
              </div>
              <div className="projectItem">
                <h3 className="projectItemTitle">
                  <a href="https://noteapp.roselynwill.com" target="_blank" rel="noopener noreferrer">Note Taking Web Application</a>
                </h3>
                <div className="projectItemDescription">
                  This note-taking app was built using React and Redux. It includes features such as saving notes to local storage and editing existing notes.
                </div>
                <div className="codePill">React</div>
                <div className="codePill">Redux</div>
                <div className="codePill">AWS-CDK</div>
              </div>
              <div className="projectItem">
                <h3 className="projectItemTitle">
                  <a href="https://giraffimation.com" target="_blank" rel="noopener noreferrer">Art Blog</a>
                </h3>
                <div className="projectItemDescription">
                This art blog contains login functionality, admin post control, with AWS Lambda, DynamoDB, and Rest API integration.
                </div>
                <div className="codePill">React</div>
                <div className="codePill">Redux</div>
                <div className="codePill">AWS-CDK</div>
                <div className="codePill">RestAPI</div>
                <div className="codePill">DynamoDB</div>
              </div>
              {/* Add more project items */}
            </div>
          </div>
        </div>
        <footer>
          <p className="footer-links">
            &copy; {new Date().getFullYear()} Rose W. | All Rights Reserved 
            </p>
        </footer>
      </div>
      
  );
}

export default App;
